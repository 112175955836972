var __defProp = Object.defineProperty;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __assign = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __rest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};
import {getVariantColor} from "@the-game/components/utils";
import {calcRem} from "@the-game/components/utils";
import styled from "@emotion/styled";
import {insetFocusStyle} from "@the-game/components/utils";
export const StyledInputContainer = styled("div")({
  position: "relative",
  display: "flex"
});
export const StyledInput = styled("input")((_a) => {
  var {theme, variant, fluid} = _a, props = __rest(_a, ["theme", "variant", "fluid"]);
  return __assign(__assign({
    display: "flex",
    flexGrow: fluid ? 1 : void 0,
    width: fluid ? "100%" : void 0,
    padding: calcRem(theme.spacing.sm),
    background: theme.colors.white,
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: getVariantColor(variant, theme),
    borderRadius: 0,
    outline: "none",
    "&:focus": {
      borderColor: theme.colors.primary
    }
  }, insetFocusStyle(theme, props.sportName)), {
    "&[disabled]": {
      backgroundColor: theme.colors.palette.neutral30,
      borderColor: theme.colors.palette.neutral70,
      color: theme.colors.palette.neutral70
    }
  });
});
