import styled from "@emotion/styled";
import Box from "../Box";
import {Cross, Info} from "../Icons";
import {StyledLinkButton} from "../LinkButton/LinkButton.styled";
import {breakpoint, calcRem} from "../utils";
export const StyledAlertBanner = styled("div")(({theme}) => ({
  backgroundColor: "transparent",
  color: theme.colors.white,
  display: "grid",
  gridTemplateColumns: "repeat(16, 1fr)",
  minHeight: 104,
  [breakpoint("sm")]: {
    gridTemplateColumns: "repeat(15, 1fr) 104px"
  }
}));
export const StyledAlertBannerBox = styled(Box)(() => ({
  color: "inherit",
  gridColumn: "1 / -2",
  display: "grid",
  gridTemplateColumns: "30px 1fr",
  gridTemplateRows: "auto",
  [breakpoint("lg")]: {
    gridColumn: "3 / -2"
  },
  [breakpoint("xl")]: {
    gridColumn: "1 / -2"
  }
}));
export const StyledAlertBannerHeader = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  gridRow: 1,
  gridColumn: "1 / -1"
}));
export const StyledInfoIcon = styled(Info)(({theme}) => ({
  fill: theme.colors.white,
  width: calcRem(20),
  marginRight: theme.spacing.xs
}));
export const StyledAlertBannerText = styled("p")(() => ({
  gridColumn: 2
}));
export const StyledAlertActionButton = styled(StyledLinkButton)(({theme}) => ({
  color: theme.colors.white,
  textDecoration: "none",
  padding: theme.spacing.md,
  gridColumn: 16
}), []);
export const StyledAlertActionButtonCross = styled(Cross)({
  width: calcRem(24)
});
