// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { environmentSchema, EnvironmentSchema } from './validate-environment'

export const environment: EnvironmentSchema = environmentSchema.parse({
    production: false,
    picksEnabled: process.env.NX_PICKS_ENABLED === 'true',
    baseUrl: process.env.NX_SITE_URL || 'http://localhost:4200',
    apiGatewayUrl:
        process.env.NX_API_GATEWAY_URL || 'https://api.thegame.swmdigital.io',
    clientId:
        process.env.NX_USE_AUTH0 === 'true'
            ? process.env.NX_AUTH0_CLIENT_ID ||
              'oweNxwHY5AYOLaNKTUMBI7aV8o2OZKpT'
            : process.env.NX_AUTH_CLIENT_ID ||
              'eeq5cwzhen0y1na59vpi7h69xfkgtbb0',
    idp:
        process.env.NX_USE_AUTH0 === 'true'
            ? process.env.NX_AUTH0_SERVER_BASE
                ? `https://${process.env.NX_AUTH0_SERVER_BASE}`
                : 'https://dev-wanews.au.auth0.com'
            : process.env.NX_AUTH_ENDPOINT ||
              'https://id-dev.swmdigital.io/connect',
    recaptureSiteKey:
        process.env.NX_RECAPTCHA_SITE_KEY ||
        '6LdAINoZAAAAAKN1lqff5DtXQQlYCcDTtca4GAqP',
    gptNetworkCode: process.env.NX_GPT_NETWORK_CODE || '60035833',
    gptParentCode: process.env.NX_GPT_PARENT_CODE || 'wan/thegame/web',
    tabtouchWebsiteUrl:
        process.env.NX_TABTOUCH_WEBSITE_URL || 'https://wwwqa.tabtouch.com.au',
    audience: process.env.NX_AUTH0_AUDIENCE || 'id-dev.swmdigital.io',
})
